(function () {
    var inputs = document.querySelectorAll('input[name="nav-primary-choices"]');

    for (var i = 0; i != inputs.length; i++) {
        var input = inputs[i];
        input.addEventListener('input', onNavInputChange);
    }

    // Quand on clique sur une entrée de niveau 1 pour afficher un menu secondaire
    function onNavInputChange(e) {
        var target = e.currentTarget;

        // reset la scrollbar du sous-menu actif
        var radioId = target.value;
        var submenuWrapper = document.getElementById('nav-primary-submenu-wrapper-' + radioId);
        if(submenuWrapper) submenuWrapper.scrollTop = 0;
    }
})();