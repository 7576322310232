

/* Initialisation du slider */
(function () {

    var containerTabs = document.querySelectorAll('.bloc-parcours .tabs-parcours');

    for (var i=0; i != containerTabs.length; i++) {
        var container = containerTabs[i];
        initTabs(container);
    }

    function initTabs(container) {
        var tabs = container.querySelectorAll('label');

        for (var i = 0; i != tabs.length; i++) {
            var tab = tabs[i];
            tab.addEventListener('click', onClickTab);
        }
    }


    function onClickTab(e) {
        e.preventDefault();
        var target = e.currentTarget;

        // empèche le scroll en haut de page au click sur le label
        var radioId = target.getAttribute('for');
        var radio = document.querySelector('#' + radioId);
        radio.click();
    }


})();